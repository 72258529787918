export const resourceData = [
  {
    event: "Hawks Talks 2023",
    note: "Presentations from our open forum Hawk Talks, held on December 11th, 2023.",
    links: [
      {
        title: "FRC 2265: What Does it Mean to be a Leader?",
        link: "https://docs.google.com/presentation/d/1BOQm8meaDadzbEmcTaxOsXkAkzySNNka8rnq62EwXBA/edit?usp=sharing",
      },
      {
        title: "FIRST Impact in a Nutshell",
        link: "https://docs.google.com/presentation/d/1slnsUawQ759MDD7UjKo-igShuzHCaF_ShkTSX_1En2I/edit?usp=drive_link",
      },
      {
        title: "FRC 694/1880: Student Finance",
        link: "https://docs.google.com/presentation/d/1t8lb8dD4PB8T-P5416LWU5Pp0jrp8BCju_HIRWDsxiE/edit?usp=drive_link",
      },
      {
        title: "Hawk Tank: The Basics, The Nerdy, and Bambu X1C (3D Printing)",
        link: "https://docs.google.com/presentation/d/1vUl1K65VySZCMYhBRYlUG1s9fiLt0R_RaQWH6zsTsJg/edit?usp=drive_link",
      },
      {
        title: "Rags to Riches: A ScoutApp Story",
        link: "https://docs.google.com/presentation/d/1F_oGGf4y26NZE3nEKzA0_hDvthZsNXYi9ErePtYbJpA/edit?usp=drive_link",
      },
      {
        title: "FRC 1796: Lessons Learned - Swerve Programming : Autos & Debugging",
        link: "https://docs.google.com/presentation/d/16RQsVKSWQYFtWfSkQez7q_Pm3Wv1CBvi-T0EwzC1iRk/edit?usp=drive_link",
      },
      {
        title: "SAS: Scouting and Strategy",
        link: "https://docs.google.com/presentation/d/1D4zhDCFZz1Vg_Lt9Y-XWOcJnoFirkJ9Gvqw8AveL1HM/edit?usp=drive_link",
      },
      {
        title: "Vision with Python and Java",
        link: "https://docs.google.com/presentation/d/1fgIRuFePUxB8OItj0GAQ9iBdDnypVNIIxE_G4unPoTo/edit?usp=drive_link",
      },
      {
        title: "FRC 1155: Code Structure",
        link: "https://docs.google.com/presentation/d/1v9Mg4Id8S3SjOd1_MKlnYJgWSSb48Dlfh5oPkeiCrmk/edit?usp=sharing",
      }
    ],
  },
  {
    event: "Hawk Talks 2022",
    note: "Presentation Slides",
    rowSpan: "2",
    links: [
      {
        title: "Design",
        link: "https://docs.google.com/presentation/d/1JY5ckX9ky1aYNHT_XiNmtkUEv95Qr0DlnqGDeEOTShM/edit?usp=share_link",
      },
      {
        title: "Programming (Java)",
        link: "https://docs.google.com/presentation/d/1VKaCCPkLO2wlDlGLMwLohn7wTzazOYgVD_TFtPA_Ht8/edit?usp=share_link",
      },
      {
        title: "Student Leadership",
        link: "https://docs.google.com/presentation/d/1x7gWxpoi4pntdLmaLMmGgFctZH4H3_UHrMCIQq_ZYhU/edit?usp=share_link",
      },
      {
        title: "FIRST Impact",
        link: "https://docs.google.com/presentation/d/1o__jQl3Ea7OTY7WvUFRCFwDvr_aMgFvup-QS6DmaULs/edit?usp=share_link",
      },
      {
        title: "How to Have a Happy Team",
        link: "https://docs.google.com/presentation/d/1uLrBRvAEOc_2z9QGbe7jZy-bfX4_HuiJWoR-VOYDBiE/edit?usp=share_link",
      },
      {
        title: "Programming (Python)",
        link: "https://docs.google.com/presentation/d/12Of4TsnX6i5BWNvThF_1ZBuXpVoB43oO3DF-6LaNmXc/edit?usp=sharing",
      },
      {
        title: "Robot Build",
        link: "https://docs.google.com/presentation/d/1RVvumJUT6W4tWMWhbvYoR0beV6ge44HYfGqjPEyy6g0/edit?usp=share_link",
      },
      {
        title: "Strategy",
        link: "https://docs.google.com/presentation/d/1NCMe5AmZAlLSymBHC2zQj70PTBROtUKEM-GI0apujiQ/edit?usp=share_link",
      },
      {
        title: "Importance of Media",
        link: "https://docs.google.com/presentation/d/1i_4Ha5sfFiGpuuztf82T_4ArUy2vybGhQSj7xiFVA74/edit?usp=share_link",
      },
      {
        title: "Electrical",
        link: "https://docs.google.com/presentation/d/1CPRfafEngcvsCzCUFqQDSW3B5Yy1pDMEdMLo9TlyoE4/edit?usp=share_link",
      },
    ],
  },
  {
    event: "FIRST Impact",
    note: "From our wonderful Impact Team!",
    links: [
      {
        title: "2023 FIRST Impact Video",
        link: "https://youtu.be/yHYHPryOHDc?si=IT6XjaJT9rorCmSj",
      },
      {
        title: "2023 FIRST Impact Essay",
        link: "/media/resources/2023Impact.pdf",
      },
      {
        title: "2022 Chairman's Essay",
        link: "https://docs.google.com/document/d/1LTVNSy5ykFzg7Yg1XhSYqE2EF3wxhrK89LXZHuJpmvs/edit",
      },
      {
        title: "2022 Chairman's Video",
        link: "https://www.youtube.com/watch?v=9aW_UDvRUWc",
      },
      {
        title: "2018 Chairman's Video",
        link: "https://www.youtube.com/watch?v=vD-GRcvxOhQ",
      },
      {
        title: "2017 Chairman's Video",
        link: "https://www.youtube.com/watch?v=75bAk8zKhBA",
      },
      {
        title: "2015 Chairman's Video",
        link: "https://www.youtube.com/watch?v=-2ma0OHa9PY",
      },
    ],
  },
  {
    event: "2024 - Crescendo",
    note: "Resources for the 2024 season!",
    links: [
      {
        title: "2024 Strategy Board",
        link: "/media/resources/2024StrategyBoard.pdf",
      },
      {
        title: "Kickoff Schedule",
        link: "/media/resources/2024Kickoff.pdf",
      },
    ],
  },
  {
    event: "2023 - Charged Up!",
    note: "Resources for the 2023 season!",
    links: [
      {
        title: "A Guide to Effective Analysis",
        link: "https://docs.google.com/presentation/d/1lEZN9vTsc3uB5pQanEX0M_rqYlt07gpqt2hyezgHtlM/edit?usp=sharing",
      },
      {
        title: "2023 Strategy Board",
        link: "https://www.chiefdelphi.com/t/2601s-2023-strategy-board/424750",
      },
      {
        title: "HAWKBIBI Tech Binder",
        link: "/media/resources/2023techBinder.pdf",
      },
    ],
  },
];
