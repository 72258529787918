import React from "react";
import { subteamList } from "./data";

const SubteamPage = () => {
  return (
    <div>
      <h1 className="pageTitle">Subteams</h1>
      <section className="grid grid-cols-1 lg:grid-cols-2 m-4">
        {subteamList.map((subteam, i) => (
          <div className="mb-20 mx-4" key={i}>
            <h2>{subteam.name}</h2>
            <div className="self-baseline flex justify-center">
              <picture>
                <source
                  srcSet={"/media/subteams/avif/" + subteam.fileName + ".avif"}
                  type="image/avif"
                />
                <source
                  srcSet={"/media/subteams/webp/" + subteam.fileName + ".webp"}
                  type="image/webp"
                />
                <source
                  srcSet={"/media/subteams/jpg/" + subteam.fileName + ".jpg"}
                  type="image/jpg"
                />
                <img className="rounded-md" alt={subteam.name} />
              </picture>
            </div>
            <p className="px-4">{subteam.description}</p>
          </div>
        ))}
      </section>
    </div>
  );
};

export default SubteamPage;
