import { motion } from 'framer-motion';
import React from 'react';
import MediaChooser from '../../helpers/MediaChooser';
import { news } from './data';
import IGFeed from '../../components/InstagramFeed';
import Marquee from '../../components/marquee';

const HomePage = () => {
	return (
		<section className='px-4'>
			<div>
				<img src='/logo.svg' alt='Steel Hawks' className='md:w-1/3 m-auto' />

				<MediaChooser
					type='carousel'
					images={['home1', 'home2', 'home3']}
					location='home'
				/>
			</div>

			<section className='flex flex-col my-10'>
				<h2 className={'mt-6 mb-0'}>
					Have you seen BIRB following our journeys?
				</h2>
				<Marquee />
				<a
					className={
						'text-white mx-auto font-display text-2xl border-2 p-2 bg-primary mt-4 align-middle rounded-2xl hover:text-white hover:bg-secondary'
					}
					href='https://docs.google.com/forms/d/e/1FAIpQLSe56q-DU03mhXipdVp5xfXjPlKbkzy_M5fbCFX808oQwJgs-A/viewform?usp=sf_link'>
					ORDER NOW!
				</a>
			</section>

			{/*birb order form*/}
			{/* <section className={"flex justify-center my-24"}>
        <div className={"text-center flex flex-col justify-center"}>
          <h2 className={"mt-6 mb-0"}>
            Have you seen BIRB following our journeys?{" "}
          </h2>
          <p className={"mb-6"}>Well, you can get your own now!</p>
          <div className={"flex justify-center"}>
            <MediaChooser
              size={"w-2/3"}
              type="carousel"
              images={["BIRBvivian", "BIRBjie", "BIRBangela"]}
              location="birbpics"
            />
          </div>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSe56q-DU03mhXipdVp5xfXjPlKbkzy_M5fbCFX808oQwJgs-A/viewform?usp=sf_link">
            <button
              className={
                "text-white inline font-display text-2xl border-2 p-2 bg-primary mt-4 align-middle rounded-2xl hover:bg-secondary"
              }
            >
              ORDER NOW!
            </button>
          </a>
        </div>
      </section> */}

			{news.map((season) => {
				return (
					<section>
						<h1 className='pageTitle text-[3em] m-4'>
							{season.year} Season Recap
						</h1>
						<div>
							{season.events.map((event, i) => {
								return (
									<div>
										<h2 className='text-2xl md:text-4xl mb-2 overflow-hidden text-center'>
											{event.name}
										</h2>
										<div className='flex justify-center'>
											<MediaChooser
												type={event.type}
												images={event.fileNames}
												video={event.video}
												location={'events/' + event.fileLocation}
											/>
										</div>
										<p className='flex justify-center'>{event.text}</p>
									</div>
								);
							})}
						</div>
					</section>
				);
			})}

			<p className='pb-4 text-secondary'>
				Thank you to our sponsors, parents, school community, and volunteers who
				made this season possible!
			</p>

			<h2 className={'mb-2 mt-10'}>
				Check out our{' '}
				<a
					href={'https://www.instagram.com/steelhawks2601/'}
					target={'_blank'}
					className={'text-white cursor-pointer'}>
					Instagram!
				</a>
			</h2>
			<IGFeed />
		</section>
	);
};

export default HomePage;
