export const robotData = [
  {
    year: '2024',
    name: 'Hawk Rider',
    game: 'Crescendo',
    fileNames: ['hawkrider'],
    bio: 'Taking what we learned from our first swerve robot, Hawk Rider built off of our experience to create a machine set out to win. It holds the NYC Regional Industrial Design Award for its outstanding quality.',
    type: 'vid',
    video: 'https://www.youtube.com/embed/ABSAXoArzUM?si=lXBMVwoG1aCwT-Cp'
  },
  {
    year: '2023',
    name: 'Hawkbibi',
    game: 'Charged Up',
    fileNames: ['hawkbibi'],
    bio: 'Our first ever robot with swerve, Hawkbibi proved to be a reliable and consistent robot, rarely breaking and becoming a finalist at the NYC regional.',
    type: 'img',
  },
  {
    year: '2022',
    name: 'Hawktimus Prime',
    game: 'Rapid React',
    fileNames: ['robot1', 'robot2', 'robot3'],
    bio: 'Climb low, climb high, shoot low, shoot high, defend. Our robot has transformed to do it all, winning the 2022 New York Tech Valley Regional. ',
    type: 'carousel',
  },
  {
    year: '2022',
    name: 'RI6H',
    game: 'Rapid React',
    fileNames: ['ri6h'],
    bio: "You've heard of robot in 3 days... but have you heard of robot in 6 hours? Due to unexpected delivery issues at the FIRST Championship, our team quickly adapted to create RI6H. Through the hard work of our members and also the generous help of teams 254, 1678, 118, and 2590 as well as AndyMark we were able to create a fully functioning robot with a mid climber, taxi, and incredible defense all in 6 hours. Luckily, this bot has never needed to compete, but passed robot inspection at Worlds and now serves to test our Vision capabilities and driver training. ",
    type: 'img',
  },
  {
    name: 'HyLo Wren',
    year: '2020',
    game: 'Infinite Recharge',
    bio: 'Image Missing :(',
    type: '',
  },
  {
    year: '2019',
    name: 'Cygnus',
    game: 'Deep Space',
    fileNames: '2019',
    bio: '',
    type: 'img',
  },
  {
    year: '2018',
    name: 'Steel Hawking',
    game: 'Power Up!',
    fileNames: '2018',
    bio: '',
    type: 'img',
  },
  {
    year: '2017',
    name: 'Aquila',
    game: 'Steamworks',
    video: 'https://www.youtube.com/embed/sIoXR36DF2A',
    bio: 'Winner of 2017 Hudson Valley Regional.',
    type: 'vid',
  },
  {
    year: '2016',
    name: 'Red Tail',
    game: 'Stronghold',
    fileNames: '2016',
    bio: '',
    type: 'img',
  },
  {
    year: '2015',
    name: 'Raptor',
    game: 'Recycle Rush',
    video: 'https://www.youtube.com/embed/EtHq5U6SAsg',
    bio: '',
    type: 'vid',
  },
  {
    year: '2014',
    name: 'Tomahawk',
    game: 'Aerial Assist',
    video: 'https://www.youtube.com/embed/PXzYjObkA2A',
    bio: '',
    type: 'vid',
  },
];
