import { useState, useEffect } from 'react';

export default function IGFeed() {
	const [posts, setPosts] = useState([]);
	useEffect(() => {
		//contact isaac if you need help with api
		fetch(
			`https://graph.instagram.com/me/media?fields=timestamp,media_url,permalink,thumbnail_url&access_token=${process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN}`
		)
			.then((response) => {
				if (!response.ok) {
					throw new Error(response.status);
				}
				return response.json();
			})
			// .catch((error) => {alert(error)})
			.then((data) => {
				setPosts(data.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	function toDate(timestamp) {
		const date = new Date(timestamp);
		return date.toString().split(' ').splice(1, 3).join(' ');
	}

	return (
		<>
			<section
				className={
					'mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5 w-fit'
				}>
				{posts.map((post) => (
					<div
						className={'w-[288px] justify-center flex flex-col'}
						key={post.id}>
						<a
							href={post.permalink}
							className={'flex justify-center w-fit mx-auto'}>
							<img
								src={
									post['thumbnail_url'] ? post.thumbnail_url : post.media_url
								}
								alt=''
								className={
									'max-h-[288px] flex flex-col justify-center text-center rounded-md border-2 drop-shadow-xl border-gray-300 transition ease-out hover:-translate-y-3 hover:scale-105'
								}
							/>
						</a>
						<p className={'text-sm text-gray-500 text-right inline'}>
							{toDate(post.timestamp)}
						</p>
					</div>
				))}
			</section>
		</>
	);
}
