import MediaChooser from "../helpers/MediaChooser";
import { useState } from "react";
export default function Marquee() {
  const [motion, setMotion] = useState(false);
  const handleChange = () => {
    setMotion(!motion);
  };
  return (
    <section className="mx-auto">
      <div className="relative flex w-3/4 mx-auto overflow-hidden ">
        <div
          className={`flex shrink-0 gap-8 select-none justify-around min-w-fit pr-8 ${
            motion ? "animate-none" : "animate-marquee"
          }`}
        >
          <MediaChooser location="birbpics" images="BIRBangela" type="img" />
          <MediaChooser location="birbpics" images="BIRBvivian" type="img" />
          <MediaChooser location="birbpics" images="BIRBjie" type="img" />
        </div>
        <div
          aria-hidden="true"
          className={`flex shrink-0 gap-8 select-none mx-auto overflow-hidden justify-around max-w-screen-sm md:min-w-fit ${
            motion ? "animate-none" : "animate-marquee"
          }`}
        >
          <MediaChooser location="birbpics" images="BIRBangela" type="img" />
          <MediaChooser location="birbpics" images="BIRBvivian" type="img" />
          <MediaChooser location="birbpics" images="BIRBjie" type="img" />
        </div>
      </div>
      <label className="flex justify-center">
        <input type={"checkbox"} onChange={handleChange} checked={motion} />
        <span className="align-top inline">Disable Motion</span>
      </label>
    </section>
  );
}
