import React from "react";

export const news = [
  // {
  //   year: 2022,
  //   events: [
  //     {
  //       name: "Tech Valley Regional",
  //       fileNames: ["NYTR"],
  //       fileLocation: "NYTR",
  //       type: "img",
  //       text: (
  //         <p className="mb-8 text-[15px] text-center px-2 md:text-lg md:px-4 lg:w-1/2 lg:text-xl">
  //           We took home <b>first place</b> at NY Tech Valley Regional with FRC
  //           2614 and FRC 5484, securing a spot at the championships, and picking
  //           up an <b>Entrepreneurship Award</b> along the way!
  //         </p>
  //       ),
  //     },
  //     {
  //       name: "Long Island Regional 2",
  //       fileNames: ["SBPLI2"],
  //       fileLocation: "SBPLI2",
  //       type: "img",
  //       text: (
  //         <p className="mb-8 text-[15px] text-center px-2 md:text-lg md:px-4 lg:w-1/2 lg:text-xl">
  //           We made a good run, placing fourth in the qualifiers and made it to
  //           the semifinals, as well as winning a second{" "}
  //           <b>Entrepreneurship Award</b>!
  //         </p>
  //       ),
  //     },
  //     {
  //       name: "NYC Regional",
  //       fileNames: ["NYC"],
  //       fileLocation: "NYC",
  //       type: "img",
  //       text: (
  //         <p className="mb-8 text-[15px] text-center px-2 md:text-lg md:px-4 lg:w-1/2 lg:text-xl">
  //           We ranked 5th out of 48 teams during qualifiers and made it to the
  //           playoffs as captains of the 4th seeded alliance, as well as winning
  //           the <b>Excellence in Engineering</b> award!
  //           <br /> Furthermore, our <b>Jessie Li</b> won the{" "}
  //           <b>Dean's List Finalist Award</b>!
  //         </p>
  //       ),
  //     },
  //     {
  //       name: "FIRST Championship",
  //       fileNames: ["Champs"],
  //       fileLocation: "Champs",
  //       type: "img",
  //       text: (
  //         <p className="mb-8 text-[15px] text-center px-2 md:text-lg md:px-4 lg:w-1/2 lg:text-xl">
  //           As a team, making it to Worlds was one of the most fulfilling
  //           experiences we could have had. Returning back in-person after almost
  //           2 years was definitely a challenge, but we ranked 16th of 76 teams
  //           in our division, <b>breaking our personal record!</b>
  //         </p>
  //       ),
  //     },
  //   ],
  // },
  // {
  //   year: 2023,
  //   events: [{
  //     name: "Long Island Regional 2",
  //       fileNames: ["alliance"],
  //       fileLocation: "LI2",
  //       type: "img",
  //       text: (
  //         <span className="mb-8 text-[15px] text-center px-2 md:text-lg md:px-4 lg:w-1/2 lg:text-xl">
  //           At our first competition of the <i>Charged Up!</i> season, we placed third in the playoffs! We also got to debut our newest service helping teams in need, SOS (Solving Ordeals With Steel)!
  //         </span>
  //       ),
  //   },
  //   {
  //     name: "New York Tech Valley",
  //       fileNames: ["NYTR"],
  //       fileLocation: "NYTR",
  //       type: "img",
  //       text: (
  //                 <span className="mb-8 text-[15px] text-center px-2 md:text-lg md:px-4 lg:w-1/2 lg:text-xl">
  //                     Our team was the captain of Alliance 2, fighting hard in the playoffs with our alliance partners! We also won the Team Sustainibility Award!
  //                 </span>
  //               ),
  //   },
  //   {
  //     name: "NYC Regional",
  //       fileNames: ["NYC"],
  //       fileLocation: "NYC",
  //       type: "img",
  //       text: (
  //                 <span className="mb-8 text-[15px] text-center px-2 md:text-lg md:px-4 lg:w-1/2 lg:text-xl">
  //                   At our final event of the season, we fought hard with our alliance, and became finalists after a hard fought battle! Along the way, our robot won the Quality Award! 
  //                 </span>
  //               ),
  //   }
  // ],
  // },
  {
    year: 2024,
    events: [{
      name: "Hudson Valley Regional",
        fileNames: ["IMG_0049"],
        fileLocation: "HVR",
        type: "img",
        text: (
          <span className="mb-8 text-[15px] text-center px-2 md:text-lg md:px-4 lg:w-1/2 lg:text-xl">
            Our first competition of the year consisted of learning opportunities as we continued to understand our robot further. We also were able to meet with our sponsor, Haas!
          </span>
        ),
    },
    {
      name: "New York Tech Valley",
        fileNames: ["nytr2024"],
        fileLocation: "NYTR",
        type: "img",
        text: (
                  <span className="mb-8 text-[15px] text-center px-2 md:text-lg md:px-4 lg:w-1/2 lg:text-xl">
                      At Tech Valley, we continued to improve our robot, learning from our mistakes and making adjustments as needed.
                  </span>
                ),
    },
    {
      name: "NYC Regional",
        fileNames: ["nyc2024"],
        fileLocation: "NYC",
        type: "img",
        text: (
                  <span className="mb-8 text-[15px] text-center px-2 md:text-lg md:px-4 lg:w-1/2 lg:text-xl">
                    As the 2nd ranked team at the end of qulaification matches, our team, with the skills and knowledge we had acquired throughout the season, fought our best in the playoffs and won the Industrial Design Award!
                  </span>
                ),
    }
  ],
  },
];
