export default function NewsletterSub() {
	return (
		<div id='mc_embed_shell'>
			<div id='mc_embed_signup'>
				<form
					action='https://steelhawks.us21.list-manage.com/subscribe/post?u=6095c1e29fa8e6b949da2044b&amp;id=412bef8a8e&amp;f_id=0093f5e6f0'
					method='post'
					id='mc-embedded-subscribe-form'
					name='mc-embedded-subscribe-form'
					class='validate'
					target='_self'
					novalidate=''>
					<div id='mc_embed_signup_scroll'>
						<div class='indicates-required'></div>
						<div class='mc-field-group'>
							{/* <label for='mce-EMAIL'>
								Email Address <span class='asterisk'></span>
							</label> */}
							<input
								type='email'
								name='EMAIL'
								class='required email border-2 rounded-md placeholder:text-center h-12 text-center text-lg'
								placeholder='Email Address'
								id='mce-EMAIL'
								required=''
							/>
						</div>
						<div id='mce-responses' class='clear foot'>
							<div class='response hidden' id='mce-error-response'></div>
							<div class='response hidden' id='mce-success-response'></div>
						</div>
						<div aria-hidden='true' class='absolute left-[-5000px]'>
							<input
								type='text'
								name='b_6095c1e29fa8e6b949da2044b_412bef8a8e'
								tabindex='-1'
								value=''
							/>
						</div>
						<div class='optionalParent'>
							<div class='clear foot'>
								<input
									type='submit'
									name='subscribe'
									id='mc-embedded-subscribe'
									class='button border-2 px-2 mt-4 rounded-md bg-primary hover:bg-secondary font-display uppercase w-36 h-10'
									value='Subscribe'
								/>
								<p class={'my-auto mx-0'}>
									<a
										href='http://eepurl.com/iHxSDc'
										title='Mailchimp - email marketing made easy and fun'>
										<span class='inline-block bg-transparent rounded-sm'></span>
									</a>
								</p>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}
