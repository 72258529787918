export const executiveBoard = [
  {
    name: 'Gurjot Gill',
    title: 'President',
    fileName: 'gurjot',
  },
  {
    name: 'Vivian Chen',
    title: 'Vice President',
    fileName: 'vivian',
  },
  {
    name: 'Isaac Wong',
    title: 'Head of Communications',
    fileName: 'isaac',
  },
  {
    name: 'Rachel Chan',
    title: 'Head of Mechanical',
    fileName: 'rachel',
  },
  {
    name: 'Isabelle Chen',
    title: 'Head of Electrical',
    fileName: 'isabelle',
  },
  {
    name: 'Sami Sharif',
    title: 'Head of Programming',
    fileName: 'sami',
  },
  {
    name: 'Nickolas Koe',
    title: 'Head of Design',
    fileName: 'nick',
  },
  {
    name: 'Daffny Cardoso',
    title: 'Head of CNC',
    fileName: 'daffny',
  },
  {
    name: 'Eugene Zhang',
    title: 'Head of Scouting',
    fileName: 'eugene',
  },
  {
    name: 'Shannon Jackman',
    title: "Head of Impact",
    fileName: 'shannon',
  },
  {
    name: 'Eileen Wang',
    title: 'Head of Media',
    fileName: 'eileen',
  },
];