import React, { useEffect, useState } from 'react';
import Loading from '../../components/Loading';
// import awardList from './data';
import { motion } from 'framer-motion';

const AwardPage = () => {
	const [loading, setLoading] = useState(true);
	async function keyToName(key) {
		console.log(key);
	}

	let awards = [];
	useEffect(() => {
		fetch(
			`https://www.thebluealliance.com/api/v3/team/frc2601/awards?X-TBA-Auth-Key=${process.env.REACT_APP_TBA_KEY}`
		)
			.then((response) => {
				if (!response.ok) {
					throw new Error(response.status);
				}
				return response.json();
			})
			.then((data) => {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				awards = data.map((award) => {
					if (award.name.includes(`Dean's List`)) {
						for (let i in award.recipient_list) {
							switch (award.recipient_list[i].team_key) {
								case 'frc2601':
									return {
										name: `${award.name} (${award.recipient_list[i].awardee})`,
										year: award.year,
										event: award.event_key,
									};
								default:
									break;
							}
						}
					}
					return { name: award.name, year: award.year, event: award.event_key };
				});
				console.log(awards);
			})
			.then(async () => {
				const events = {
					nyny: 'New York City Regional',
					cars: 'Carson Division',
					nyli: 'Long Island Regional',
					nysu: 'Hudson Valley Regional',
					nyli2: 'SBPLI Long Island Regional #2',
					nytr: 'Tech Valley Regional',
				};

				awards.map((award) => {
					award.eventName = events[award.event.slice(4)];
				});
				setLoading(false);
				setAwardList(awards.reverse());
			})
			.catch((error) => {
				setLoading(false);
				document.getElementById('error').innerHTML =
					'Could not retrieve data at this time :( <br/>' + error;
			});
	}, []);

	const [awardList, setAwardList] = useState([]);

	return (
		<div>
			<h1 className='pageTitle'>Awards</h1>
			<h2 id={'error'} />
			{loading ? <Loading /> : null}
			<div className='justify-center flex'>
				<section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'>
					{awardList.map((award, i) =>
						award.name.includes('Wildcard') ? (
							''
						) : (
							<motion.div
								className='banner'
								key={i}
								initial={{ opacity: 0, y: 100 }}
								whileInView={{
									opacity: 1,
									y: 0,
									transition: { duration: 0.3, delay: i * 0.05 },
								}}
								viewport={{ once: true }}>
								<div className='flex bg-inherit justify-center'>
									<img
										alt='FIRST Organization Logo'
										className='bg-inherit mt-2 w-24'
										src='/media/first_icon.svg'
									/>
								</div>
								<p className='bannerText mt-4 italic text-2xl'>{award.name}</p>
								<div className='bannerText px-4 uppercase absolute bottom-[3%] left-0'>
									{award.year} {award.eventName}
								</div>
							</motion.div>
						)
					)}
				</section>
			</div>
		</div>
	);
};

export default AwardPage;
