import React from "react";

  const SteelbucksPage = () => {
  return (
    <div className="md:px-24">
      <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script><iframe className="airtable-embed airtable-dynamic-height" src="https://airtable.com/embed/appoqlHHfMlRAziIz/shrlZ7tfWGjYWImSQ?backgroundColor=greenLight" frameBorder="0" onmousewheel="" width="100%" height="800px"></iframe>
    </div>
  );
};

export default SteelbucksPage;
