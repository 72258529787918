import React from "react";
const ErrorBoundary = () => {
  return (
    <div>
      <h1 className={'pageTitle'}>Unknown Page!</h1>
      <p className={'mb-8'}>Hmm... Check that you typed the page in correctly.</p>
    </div>
)
}

export default ErrorBoundary
